<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.bank')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                :rules="rule"
                :label="$vuetify.lang.t('$vuetify.bankName')"
                v-model="myObj.name"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                :rules="rule"
                :label="$vuetify.lang.t('$vuetify.accountName')"
                v-model="myObj.account"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                :rules="rule"
                :label="$vuetify.lang.t('$vuetify.bankNo')"
                v-model="myObj.no"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                :rules="rule"
                :label="$vuetify.lang.t('$vuetify.addr')"
                v-model="myObj.branch"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                :rules="rule"
                :label="$vuetify.lang.t('$vuetify.Country')"
                v-model="myObj.country"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                :rules="rule"
                :label="$vuetify.lang.t('$vuetify.SwiftCode')"
                v-model="myObj.swift_code"
              />
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.note')"
                outlined
                height="180"
                v-model="myObj.note"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />
      <v-btn color="red darken-2" outlined @click="cancelForm" width="120">{{
        $vuetify.lang.t("$vuetify.cancel")
      }}</v-btn>
      <v-btn color="green darken-2" @click="validate" width="120">{{
        $vuetify.lang.t("$vuetify.save")
      }}</v-btn>
      <v-spacer />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
export default {
  name: "bankAccCRUD",
  props: { dialog: Boolean, myObj: Object },
  data() {
    return {
      valid: false,
      rule: [(v) => !!v || "this field is required"],
    };
  },
  methods: {
    cancelForm() {
      this.resetValidation();
      this.$emit("update:dialog", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.resetValidation();
        this.$emit("handleData", this.myObj);
      } else {
        console.log("False");
      }
    },
  },
  watch: {
    dialog: function () {
      this.dialog ? this.$refs.form.resetValidation() : "";
    },
  },
};
</script>

<style></style>
